import React from "react";
import Form from "../components/Form";

const Order = (props: {
  theme: string;
  themeMode: any;
  themeToggle: () => void;
}) => {
  const { theme, themeToggle, themeMode } = props;
  return (
    <>
      <h1 style={{ color: themeMode.contrast }}>Order</h1>
      <Form></Form>
    </>
  );
};

export default Order;
