import React from "react";
import styled from "styled-components";

const Paragraph = styled.p`
  text-align: left;
  color: ${({ theme }) => theme.contrast};
`;

const About = (props: {
  theme: string;
  themeMode: any;
  themeToggle: () => void;
}) => {
  const { theme, themeToggle, themeMode } = props;
  return (
    <>
      <h1 style={{ color: themeMode.contrast }}>
        Welcome to Dumpster Fire Tech LLC!
      </h1>
      <Paragraph>
        At Dumpster Fire Tech, we believe that creativity knows no age limits.
        Founded by a talented and ambitious 10-year-old entrepreneur, Dumpster
        Fire Tech LLC is a laser etching business that brings joy and
        personalized designs to customers through the power of laser technology.
      </Paragraph>
      <Paragraph>
        Our young founder, Byol-ee "GlitterCat", has always had a passion for
        art and technology. With a knack for design and an entrepreneurial
        spirit, she decided to embark on this exciting journey of running her
        own business. Despite her age, GlitterCat is determined to provide
        top-notch laser etching services and deliver outstanding results to her
        customers.
      </Paragraph>
      <Paragraph>
        Dumpster Fire Tech LLC takes pride in offering a range of laser etching
        services to cater to various needs. Whether it's customizing a special
        gift, personalizing an item, or creating unique pieces of art,
        GlitterCat and her team (her papa and little sister) are dedicated to
        turning ideas into reality. With attention to detail and a commitment to
        quality craftsmanship, every project is handled with care and precision.
      </Paragraph>
      <Paragraph>
        But Dumpster Fire Tech LLC isn't just about laser etching—it's about
        bringing smiles to people's faces. Our young entrepreneur understands
        the importance of customer satisfaction and goes above and beyond to
        ensure that each client receives a delightful experience. GlitterCat
        values open communication, listening to customer requirements, and
        providing helpful suggestions to bring their visions to life.
      </Paragraph>
      <Paragraph>
        Dumpster Fire Tech LLC is not just a business; it's a story of passion,
        determination, and dreams. By choosing to support our venture, you are
        not only receiving a unique laser etching service but also encouraging
        the aspirations of a young entrepreneur. Every purchase contributes to
        GlitterCat's growth, enabling her to explore new opportunities and
        expand her skills in the world of laser technology.
      </Paragraph>
      <Paragraph>
        Thank you for visiting Dumpster Fire Tech LLC! We look forward to
        serving you and exceeding your expectations with our creative laser
        etching services. Feel free to explore our portfolio, get in touch with
        us for inquiries, and join us on this exciting journey of art and
        innovation.
      </Paragraph>
      <p style={{ color: themeMode.contrast }}>
        Together, let's ignite the spark of creativity!
      </p>
    </>
  );
};

export default About;
