import { HiMoon } from "react-icons/hi";
import { FaSun } from "react-icons/fa";
import { Container, Icons } from "./styles";
// import styled from "@emotion/styled";

// const StyledLink = styled("span")({
//   fontSize: "xxx-large",
//   justifyContent: "start",
//   "&:hover": {
//     color: "#e70f0f",
//   },
// });

interface ThemeToggleProps {
  themeToggle: () => void;
}

function ToggleButton({ themeToggle }: ThemeToggleProps) {
  return (
    <Container>
      <label htmlFor="checkbox" className="switch">
        <input
          id="checkbox"
          type="checkbox"
          onClick={themeToggle}
          onChange={() => false}
          checked={window.localStorage.getItem("theme") === "light"}
        />
        <Icons>
          {window.localStorage.getItem("theme") !== "light" ? (
            // <StyledLink>
            <a>
              <HiMoon />
            </a>
          ) : (
            // </StyledLink>
            // <StyledLink>
            <a>
              <FaSun />
            </a>
            // </StyledLink>
          )}
        </Icons>
      </label>
    </Container>
  );
}

export default ToggleButton;
