import React, { useState } from "react";
import GlassStemlessWineImage from "../assets/cups/glass/Glass_Stemless-Wine.png";
import GlassMugImage from "../assets/cups/glass/Glass_Mug.png";
import GlassBeerCanImage from "../assets/cups/glass/Glass_Beer-Can.png";
import GlassPintImage from "../assets/cups/glass/Glass_Pint.png";
import MetalMugBlackImage from "../assets/cups/metal/Metal_Coffee-Mug_Black.png";
import MetalMugStainlessSteelImage from "../assets/cups/metal/Metal_Coffee-Mug_Stainless-Steel.png";
import CoinBrassGoldImage from "../assets/Coins/Brass/Brass-Gold.jpg";
import CoinBrassSilverImage from "../assets/Coins/Brass/Brass-Silver.jpg";
import CoinAlloyDarkGoldImage from "../assets/Coins/Alloy/Alloy-Dark_Gold.png";
import CoinAlloyLightGoldImage from "../assets/Coins/Alloy/Alloy-Light_Gold.png";
import CoinAlloySilverImage from "../assets/Coins/Alloy/Alloy-Silver.png";
import CoinAlloyCopperImage from "../assets/Coins/Alloy/Alloy-Copper.png";
import PlaqueWoodBlackImage from "../assets/Plaques/Wood-9x12-Black.png";
import PlaqueWoodCherryImage from "../assets/Plaques/Wood-9x12-Cherry.png";

import "./Form.css";
import data from "./products.json";

interface StyleOption {
  name: string;
  image: string;
}

interface Material {
  name: string;
  styles: StyleOption[];
}

interface Product {
  name: string;
  materials: Material[];
}

const Form: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState("");
  const [options, setOptions] = useState("");
  const [styleOptions, setStyleOptions] = useState("");
  const [styleImage, setStyleImage] = useState("");
  const [exampleImages, setExampleImages] = useState<string[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleCopy = () => {
    let formData = `Name: ${name}\nEmail: ${email}\nProduct: ${product}\nMaterial: ${options}\nStyle: ${styleOptions}`;
    navigator.clipboard.writeText(formData);
    setShowModal(false);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 9000);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedProduct = e.target.value;
    setProduct(selectedProduct);
    setOptions("");
    setStyleOptions("");

    // Set example images based on selected product
    if (selectedProduct === "Cups") {
      setExampleImages([
        GlassStemlessWineImage,
        GlassMugImage,
        GlassBeerCanImage,
        GlassPintImage,
        MetalMugBlackImage,
        MetalMugStainlessSteelImage,
      ]);
    } else if (selectedProduct === "Coins") {
      setExampleImages([
        CoinBrassGoldImage,
        CoinBrassSilverImage,
        CoinAlloyDarkGoldImage,
        CoinAlloyLightGoldImage,
        CoinAlloySilverImage,
        CoinAlloyCopperImage,
      ]);
    } else if (selectedProduct === "Plaques") {
      setExampleImages([PlaqueWoodBlackImage, PlaqueWoodCherryImage]);
    }
  };

  const handleOptionsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = e.target.value;
    setOptions(selectedOptions);
    setStyleOptions("");

    // Update example images based on selected options
    if (product === "Cups" && selectedOptions === "Glass") {
      setExampleImages([
        GlassStemlessWineImage,
        GlassMugImage,
        GlassBeerCanImage,
        GlassPintImage,
      ]);
    } else if (selectedOptions === "Metal") {
      setExampleImages([MetalMugBlackImage, MetalMugStainlessSteelImage]);
    }

    if (product === "Coins" && selectedOptions === "Brass") {
      setExampleImages([CoinBrassGoldImage, CoinBrassSilverImage]);
    } else if (selectedOptions === "Alloy") {
      setExampleImages([
        CoinAlloyDarkGoldImage,
        CoinAlloyLightGoldImage,
        CoinAlloySilverImage,
        CoinAlloyCopperImage,
      ]);
    }

    if (product === "Plaques" && selectedOptions === "Wood 9x12") {
      setExampleImages([PlaqueWoodBlackImage, PlaqueWoodCherryImage]);
    } else if (selectedOptions === "Metal") {
      setExampleImages([]);
    }
  };

  const handleStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStyle = e.target.value;
    setExampleImages([]);
    setStyleOptions(selectedStyle);

    // Update example images based on selected style
    if (selectedStyle === "Stemless Wine") {
      setExampleImages([GlassStemlessWineImage]);
    }
    if (selectedStyle === "Beer Can") {
      setExampleImages([GlassBeerCanImage]);
    }
    if (selectedStyle === "Mug") {
      setExampleImages([GlassMugImage]);
    }
    if (selectedStyle === "Pint") {
      setExampleImages([GlassPintImage]);
    }
    if (selectedStyle === "Black Mug") {
      setExampleImages([MetalMugBlackImage]);
    }
    if (selectedStyle === "Stainless Steel Mug") {
      setExampleImages([MetalMugStainlessSteelImage]);
    }
    if (selectedStyle === "Gold") {
      setExampleImages([CoinBrassGoldImage]);
    }
    if (selectedStyle === "Brass Silver") {
      setExampleImages([CoinBrassSilverImage]);
    }
    if (selectedStyle === "Dark Gold") {
      setExampleImages([CoinAlloyDarkGoldImage]);
    }
    if (selectedStyle === "Light Gold") {
      setExampleImages([CoinAlloyLightGoldImage]);
    }
    if (selectedStyle === "Alloy Silver") {
      setExampleImages([CoinAlloySilverImage]);
    }
    if (selectedStyle === "Wood Black") {
      setExampleImages([PlaqueWoodBlackImage]);
    }
    if (selectedStyle === "Wood Cherry") {
      setExampleImages([PlaqueWoodCherryImage]);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            type="radio"
            id="cups"
            value="Cups"
            checked={product === "Cups"}
            onChange={handleProductChange}
          />
          <label htmlFor="cups">Cups</label>
          <input
            type="radio"
            id="coins"
            value="Coins"
            checked={product === "Coins"}
            onChange={handleProductChange}
          />
          <label htmlFor="coins">Coins</label>
          <input
            type="radio"
            id="plaques"
            value="Plaques"
            checked={product === "Plaques"}
            onChange={handleProductChange}
          />
          <label htmlFor="plaques">Plaques</label>
          <input
            type="radio"
            id="other"
            value="Other"
            checked={product === "Other"}
            onChange={handleProductChange}
          />
          <label htmlFor="other">Other</label>
        </div>
        <div>
          {product === "Cups" && (
            <div>
              <label htmlFor="options">Options: </label>
              <select
                id="options"
                value={options}
                onChange={handleOptionsChange}
              >
                <option value="">Select an option</option>
                {data.products
                  .find((p: Product) => p.name === "Cups")
                  ?.materials.map((material: Material) => (
                    <option value={material.name} key={material.name}>
                      {material.name}
                    </option>
                  ))}
              </select>
              {options && (
                <>
                  <br />
                  <label htmlFor="styleOptions">Style: </label>
                  <select
                    id="styleOptions"
                    value={styleOptions}
                    onChange={handleStyleChange}
                  >
                    <option value="">Select an option</option>
                    {data.products
                      .find((p: Product) => p.name === "Cups")
                      ?.materials.find((m: Material) => m.name === options)
                      ?.styles.map((style: StyleOption) => (
                        <option value={style.name} key={style.name}>
                          {style.name}
                        </option>
                      ))}
                  </select>
                </>
              )}
            </div>
          )}
        </div>
        <div>
          {product === "Coins" && (
            <div>
              <label htmlFor="options">Options: </label>
              <select
                id="options"
                value={options}
                onChange={handleOptionsChange}
              >
                <option value="">Select an option</option>
                {data.products
                  .find((p: Product) => p.name === "Coins")
                  ?.materials.map((material: Material) => (
                    <option value={material.name} key={material.name}>
                      {material.name}
                    </option>
                  ))}
              </select>
              {options && (
                <>
                  <br />
                  <label htmlFor="styleOptions">Style: </label>
                  <select
                    id="styleOptions"
                    value={styleOptions}
                    onChange={handleStyleChange}
                  >
                    <option value="">Select an option</option>
                    {data.products
                      .find((p: Product) => p.name === "Coins")
                      ?.materials.find((m: Material) => m.name === options)
                      ?.styles.map((style: StyleOption) => (
                        <option value={style.name} key={style.name}>
                          {style.name}
                        </option>
                      ))}
                  </select>
                </>
              )}
            </div>
          )}
        </div>
        <div>
          {product === "Plaques" && (
            <div>
              <label htmlFor="options">Options: </label>
              <select
                id="options"
                value={options}
                onChange={handleOptionsChange}
              >
                <option value="">Select an option</option>
                {data.products
                  .find((p: Product) => p.name === "Plaques")
                  ?.materials.map((material: Material) => (
                    <option value={material.name} key={material.name}>
                      {material.name}
                    </option>
                  ))}
              </select>
              {options && (
                <>
                  <br />
                  <label htmlFor="styleOptions">Style: </label>
                  <select
                    id="styleOptions"
                    value={styleOptions}
                    onChange={handleStyleChange}
                  >
                    <option value="">Select an option</option>
                    {data.products
                      .find((p: Product) => p.name === "Plaques")
                      ?.materials.find((m: Material) => m.name === options)
                      ?.styles.map((style: StyleOption) => (
                        <option value={style.name} key={style.name}>
                          {style.name}
                        </option>
                      ))}
                  </select>
                </>
              )}
            </div>
          )}
        </div>
        <button type="submit">Build Order</button>
      </form>

      {exampleImages.length > 0 && (
        <div>
          <h3>Example Images:</h3>
          {exampleImages.map((image, index) => (
            <img
              src={image}
              alt={`Example ${index + 1}`}
              key={`example-${index}`}
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          ))}
        </div>
      )}

      {styleImage && (
        <img
          src={styleImage}
          alt="Style"
          style={{ maxWidth: "200px", maxHeight: "200px" }}
        />
      )}

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close-button" onClick={handleClose}>
              X
            </button>
            <h2>Order Information</h2>
            <p>Name: {name}</p>
            <p>Email: {email}</p>
            <p>Product: {product}</p>
            <p>Style Option: {styleOptions}</p>
            <button className="modal-copy-button" onClick={handleCopy}>
              Copy to Clipboard
            </button>
          </div>
        </div>
      )}

      {showToast && (
        <div className="toast">
          <p>Copied to clipboard!</p>
        </div>
      )}
    </div>
  );
};

export default Form;
