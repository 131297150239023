import React from "react";
import banner from "../assets/home-center.png";
import styled from "styled-components";
import GlitterCat from "../assets/placeholder.jpg";

const Banner = styled.div``;

const StyledBanner = styled.img({
  height: "auto" /* Default height */,
  width: "100%",
});

const Home = (props: {
  theme: string;
  themeMode: any;
  themeToggle: () => void;
}) => {
  const { theme, themeToggle, themeMode } = props;
  return (
    <>
      <Banner />
      <h1 style={{ color: themeMode.contrast }}>Welcome to my Workshop ;-)</h1>
      <StyledBanner src={banner} alt="banner" />
      <img
        src={GlitterCat}
        alt={"place holder image"}
        style={{ height: "500px" }}
      />
    </>
  );
};

export default Home;
