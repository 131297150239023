import React from "react";

const Customize = (props: {
  theme: string;
  themeMode: any;
  themeToggle: () => void;
}) => {
  const { theme, themeToggle, themeMode } = props;
  return (
    <>
      <h1 style={{ color: themeMode.contrast }}>Customize</h1>
    </>
  );
};

export default Customize;
