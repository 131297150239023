export interface ThemeProps {
  background: string;
  primary: string;
  primaryHover: string;
  complimentary: string;
  contrast: string;
  secondary: string;
  text: string;
}

export const darkTheme: ThemeProps = {
  background: "#503B26",
  primary: "#8D9C81",
  primaryHover: "#76b8b5",
  complimentary: "#5b979d",
  contrast: "#D0D9D5",
  secondary: "#D68154",
  text: "var(--dark-text)",
};

export const lightTheme: ThemeProps = {
  background: "#E1D0C0",
  primary: "#96DACB",
  primaryHover: "#76b8b5",
  complimentary: "#5b979d",
  contrast: "#262F2B",
  secondary: "#D68154",
  text: "var(--light-text)",
};
