import React, { useState } from "react";
import PhotoWall from "../components/photoWall";
import Accordion from "../components/Accordion";
import photos from "../components/photos.json";

const Products = (props: {
  theme: string;
  themeMode: any;
  themeToggle: () => void;
}) => {
  const { theme, themeToggle, themeMode } = props;
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const accordionItems = [
    {
      title: "Coins",
      content: (
        <div>
          <PhotoWall
            photos={photos.filter((photo: any) => photo.group === "coin")}
            activePhotoIndex={activePhotoIndex}
            setActivePhotoIndex={setActivePhotoIndex}
          />
        </div>
      ),
    },
    {
      title: "Drinkware",
      content: (
        <div>
          <PhotoWall
            photos={photos.filter((photo) => photo.group === `drinkware`)}
            activePhotoIndex={activePhotoIndex}
            setActivePhotoIndex={setActivePhotoIndex}
          />
        </div>
      ),
    },
    {
      title: "Jewelry",
      content: (
        <div>
          <PhotoWall
            photos={photos.filter((photo): any => photo.group === "jewelry")}
            activePhotoIndex={activePhotoIndex}
            setActivePhotoIndex={setActivePhotoIndex}
          />
        </div>
      ),
    },
    {
      title: "Utility",
      content: (
        <div>
          <PhotoWall
            photos={photos.filter((photo) => photo.group === "utility")}
            activePhotoIndex={activePhotoIndex}
            setActivePhotoIndex={setActivePhotoIndex}
          />
        </div>
      ),
    },
    {
      title: "Gifts",
      content: (
        <div>
          <PhotoWall
            photos={photos.filter((photo) => photo.group === "gifts")}
            activePhotoIndex={activePhotoIndex}
            setActivePhotoIndex={setActivePhotoIndex}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      <h1 style={{ color: themeMode.contrast }}>Wall of Work </h1>
      <Accordion items={accordionItems} />
    </div>
  );
};

export default Products;
