import React, { useEffect, useState } from "react";
import Navbar from "./components/NavBar";
import { Route, Routes } from "react-router-dom";
import Products from "./pages/Products";
import About from "./pages/About";
import Home from "./pages/Home";
import Customize from "./pages/Customize";
import Order from "./pages/Order";
import { lightTheme, darkTheme } from "./styles";
import useThemeMode from "./hooks/useThemeMode";
import styled, { ThemeProvider } from "styled-components";

const StyledWebsite = styled.div({
  marginLeft: "8rem",
  marginRight: "8rem",
});

const App = () => {
  const { theme, themeToggle } = useThemeMode();
  const themeMode = theme === "light" ? darkTheme : lightTheme;
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  useEffect(() => {
    document.body.style.backgroundColor = themeMode.background;
  }, [theme]);
  return (
    <StyledWebsite>
      <ThemeProvider theme={themeMode}>
        <Navbar theme={theme} themeToggle={themeToggle} themeMode={themeMode} />
        <div className="container">
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  theme={theme}
                  themeToggle={themeToggle}
                  themeMode={themeMode}
                />
              }
            />
            <Route
              path="/products"
              element={
                <Products
                  theme={theme}
                  themeToggle={themeToggle}
                  themeMode={themeMode}
                />
              }
            />
            <Route
              path="/customize"
              element={
                <Customize
                  theme={theme}
                  themeToggle={themeToggle}
                  themeMode={themeMode}
                />
              }
            />
            <Route
              path="/order"
              element={
                <Order
                  theme={theme}
                  themeToggle={themeToggle}
                  themeMode={themeMode}
                />
              }
            />
            <Route
              path="/about"
              element={
                <About
                  theme={theme}
                  themeToggle={themeToggle}
                  themeMode={themeMode}
                />
              }
            />
          </Routes>
        </div>
      </ThemeProvider>
    </StyledWebsite>
  );
};

export default App;
