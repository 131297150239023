import { Link, useMatch, useResolvedPath } from "react-router-dom";
import Logo from "../assets/Dumpster Fire Tech.png";
import ToggleButton from "./ToggleButton";
import styled from "styled-components";
import React from "react";

const StyledNavBar = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "stretch",
  gap: "2rem",
  padding: "0 .5rem",
});

const Navbar = (props: {
  theme: string;
  themeMode: any;
  themeToggle: () => void;
}) => {
  const { theme, themeToggle, themeMode } = props;

  const CustomLink = ({
    to,
    children,
    ...props
  }: {
    to: string;
    children: React.ReactNode;
  }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    return (
      <StyledNavBar style={{ backgroundColor: themeMode.primary }}>
        <li className={isActive ? "active" : ""}>
          <Link to={to} {...props}>
            {children}
          </Link>
        </li>
      </StyledNavBar>
    );
  };

  return (
    <StyledNavBar
      className="nav"
      style={{ backgroundColor: themeMode.primary, color: themeMode.contrast }}
    >
      <Link to="/" className="site-title">
        <img className="nav-logo" src={Logo} alt={"Dumpster Fire Tech logo"} />
        Dumpster Fire Tech
      </Link>
      <ul>
        <CustomLink to="/products">Products</CustomLink>
        <CustomLink to="/order">Build-Order</CustomLink>
        {/*<CustomLink to="/pricing">Order</CustomLink>*/}
        <CustomLink to="/about">About</CustomLink>
        <ToggleButton themeToggle={themeToggle} />
      </ul>
    </StyledNavBar>
  );
};

export default Navbar;
